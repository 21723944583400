<template>
  <div>
    <v-card>
      <v-toolbar dense dark color="primary">
        <v-toolbar-title><h4 class="font-weight-light">PRE LIQUIDATION REPORT</h4>
        </v-toolbar-title>
      </v-toolbar>
      <v-layout row wrap class="align-center mx-2 mt-5">
        <v-layout col>
          <v-flex xs12 md3>
            <v-select
              v-model="month_of_id"
              class="mx-2"
              :items="month_of_items"
              item-value="id"
              item-text="month_of"
              label="Month Of"
              required
              :rules="rules.combobox_rule"
              dense
              @change="selected_month"
              outlined
            ></v-select>
            <v-select
              outlined
              class="mx-2"
              v-model="category_id"
              dense
              label="Category"
              :items="category_items"
              item-value="id"
              item-text="category"
              :rules="rules.combobox_rule"
              @change="selected_category"
            ></v-select>
            <v-select
              outlined
              class="mx-2"
              v-model="branch_id"
              dense
              label="Branch"
              :items="branch_items"
              item-value="id"
              item-text="branch_code"
              @change="selected_branch"
            ></v-select>
            <v-col cols="12" v-show="alert">
              <v-alert
                color="warning"
                text
                class="mb-0"

              >
                <div class="d-flex align-start">
                  <v-icon color="warning">
                    {{ icons.mdiAlertOutline }}
                  </v-icon>

                  <div class="ms-3">
                    <p class="text-base font-weight-medium mb-1">
                      {{alert_message}}
                    </p>
                  </div>
                </div>
              </v-alert>
            </v-col>
            <h2>Total: P {{total_amount}}</h2>
            <v-data-table dense
                          :headers="headers2"
                          :items="vouchered_items"
            >
              <template v-slot:item="{ item }">
                <tr
                  @click="activerow(item)"
                  :class="{'info white--text': item.id===selectedDepositId}"
                >
                  <td>
                    {{ item.particulars }}
                  </td>
                  <td>
                    {{ formatPrice(item.amount )}}
                  </td>
                </tr>
              </template>
            </v-data-table>
          </v-flex>

          <v-flex xs12 md9 class="mx-2">
            <h2>Request Data | Total: P {{total_amount2}}</h2>
            <v-data-table dense
                          :headers="headers"
                          :items="data_items"
            >
              <template v-slot:item="{ item }">
                <tr>
                  <td>
                    {{ item.id }}
                  </td>
                  <td>
                    {{ item.request_id }}
                  </td>
                  <td>
                    {{
                    item.cv!=null?'CV#'+item.cv.voucher_no:(item.ckv!=null?'CKV#'+item.ckv.voucher_no:(item.cv!=null?'FTV#'+item.ftv.voucher_no:''))
                    }}
                  </td>
                  <td>
                    {{ item.cv!=null?item.cv.cheque_no:item.cheque_no }}
                  </td>
                  <td>
                    {{ item.cv!=null?item.cv.cheque_date:item.cheque_date }}
                  </td>
                  <td>
                    {{ item.description }}
                  </td>
                  <td>
                    {{ item.credit_to }}
                  </td>
                  <td>
                    {{ item.payment_for }}
                  </td>
                  <td>
                    {{ formatPrice(item.amount) }}
                  </td>
                </tr>
              </template>
            </v-data-table>
          </v-flex>
        </v-layout>
      </v-layout>
    </v-card>
    <snack-bar-dialog
      :snackbar_flag="this.snackbar"
      :color="this.snackbar_color"
      :snackbar_text="this.snackbar_text"
    />
  </div>
</template>

<script>
  import {
    mdiAccountOutline,
    mdiAccountCashOutline,
    mdiInformationOutline,
    mdiGoogleCirclesCommunities, mdiCharity, mdiGift, mdiHandshakeOutline
  } from "@mdi/js";
  import moment from "moment";
  import {mapActions, mapGetters} from "vuex";
  import snackBarDialog from '@/components/dialogs/notifications_dialog/Snackbar'
  import category from "@/store/module/data/category";

  const initialState = () => {
    return {
      alert: false,
      alert_message: '',
      total_amount: 0,
      total_amount2: 0,
      headers: [
        {text: 'ID', value: 'date_of_deposit', sortable: false},
        {text: 'Request ID', value: 'tin', sortable: false},
        {text: 'Voucher #', value: 'last_name', sortable: false},
        {text: 'Cheque #', value: 'last_name', sortable: false},
        {text: 'Cheque Date', value: 'last_name', sortable: false},
        {text: 'Description', value: 'last_name', sortable: false},
        {text: 'Credit To', value: 'last_name', sortable: false},
        {text: 'Payment For', value: 'last_name', sortable: false},
        {text: 'Amount', value: 'last_name', sortable: false},
      ],
      headers2: [
        {text: 'Particulars', value: 'date_of_deposit', sortable: false},
        {text: 'Total Amount', value: 'last_name', sortable: false},
      ],
      data_items: [],
      month_of_id: '',
      request_items: [],
      month_of_items: [],
      category_id: '',
      category_items: [],
      vouchered_items: [],
      branch_id: -1,
      branch_items: [],
      selectedDepositId: -1,
    }
  }
  export default {
    components: {
      snackBarDialog,
    },
    setup() {
      return {
        icons: {
          mdiAccountOutline,
          mdiAccountCashOutline,
          mdiInformationOutline,
        },
      }
    },
    data() {
      return initialState()
    },
    mounted() {
      this.initialize_data()
    },
    computed: {
      ...mapGetters('form_rules', ['rules']),
      ...mapGetters('authentication', ['employee_id', 'month_of']),
      ...mapGetters('system_data', ['snackbar', 'snackbar_color', 'snackbar_text', 'company_logo']),
    },
    methods: {
      ...mapActions('system_data', ['change_snackbar']),
      ...mapActions('transaction_months', ['list_of_transaction_month']),
      ...mapActions('request', ['list_of_category_have_voucher_per_month','list_of_branches_have_voucher_per_month']),
      ...mapActions('request_data', ['list_of_particulars_have_voucher']),
      initialize_data() {
        this.list_of_transaction_month()
          .then(response => {
            this.month_of_items = response.data
          })
          .catch(error => {
            console.log(error)
          })
      },
      selected_month() {
        this.data_items = []
        this.vouchered_items = []
        this.total_amount = 0
        this.total_amount2 = 0
        this.category_id = ''
        this.list_of_category_have_voucher_per_month({
          month_of_id: this.month_of_id
        })
          .then(response => {
            this.category_items = response.data
          })
          .catch(error => {
            console.log(error)
          })
      },
      selected_category() {
        this.data_items = []
        this.vouchered_items = []
        this.total_amount = 0
        this.total_amount2 = 0
        this.branch_id=-1
        this.list_of_branches_have_voucher_per_month({
          month_of_id: this.month_of_id,
          category_id: this.category_id,
        })
          .then(response => {
            this.branch_items = response.data
            this.branch_items.splice(0, 0, {
              id: 0,
              branch_code: 'All',
            })
          })
          .catch(error => {
            console.log(error)
          })
      },
      selected_branch() {
        this.data_items = []
        this.vouchered_items = []
        this.total_amount = 0
        this.total_amount2 = 0
        this.alert = true
        this.alert_message = 'PLEASE WAIT A MOMENT...'
        this.list_of_particulars_have_voucher({
          month_of_id: this.month_of_id,
          category_id: this.category_id,
          branch_id: this.branch_id,
        })
          .then(response => {
            this.alert = false
            this.vouchered_items = response.data
            var tto = 0;
            this.vouchered_items.forEach(function (item) {
              tto += parseFloat(item.amount)
            });
            this.total_amount = this.formatPrice(tto)
          })
          .catch(error => {
            console.log(error)
          })
      },
      date_format(value) {
        return moment(value)
      },
      formatPrice(value) {
        let val = (value / 1).toFixed(2).replace(',', '.')
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
      },
      activerow(value) {
        this.data_items = value.cv
        this.selectedDepositId = value.id;
        var tto = 0;
        this.data_items.forEach(function (item) {
          tto += parseFloat(item.amount)
        });
        this.total_amount2 = this.formatPrice(tto)
      },
    },
  }
</script>
